var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('div',[_c('OtherLoading')],1):_c('div',{staticClass:"text-normal"},[_c('HeaderPanel',{attrs:{"dontWrap":"","title":_vm.ticketDetail?.ticket?.name,"placeholder":"Search Subject","hasFilter":false,"hasSearch":false,"hasDropdown":([1, 2].includes(_vm.formStatus) && _vm.ticketDetail?.ticket.deleted != 1) ||
        (_vm.$store.state.permission.Permission.response.includes(73) &&
          _vm.ticketDetail?.ticket.deleted != 1)},scopedSlots:_vm._u([{key:"filter-title",fn:function(){return [(
            _vm.$store.state.permission.Permission.response.includes(73) &&
            _vm.ticketDetail?.ticket.deleted === 1
          )?_c('span',{staticClass:"badge badge-danger badge-pill"},[_vm._v(" Deleted ")]):(
            _vm.ticketDetail?.ticket.sla_status === 1 &&
            _vm.ticketDetail?.ticket.ticket_status_id === 1
          )?_c('span',{staticClass:"badge badge-new badge-pill"},[_vm._v(" New ")]):_vm._e()]},proxy:true},{key:"content-dropdown",fn:function(){return [([1, 2].includes(_vm.formStatus))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.updateActivityByAction(2)}}},[_c('span',[_vm._v("Close")])]):_vm._e(),(_vm.$store.state.permission.Permission.response.includes(73))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.updateActivityByAction(3)}}},[_c('span',[_vm._v("Delete")])]):_vm._e()]},proxy:true},{key:"frontAction",fn:function(){return [(_vm.ticketDetail.is_anonymous)?_c('b-button',{staticClass:"btn-save mr-2",attrs:{"variant":"custom-outline-primary"},on:{"click":_vm.handleCustomer}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"user-plus"}}),_vm._v(" Assign To Customer ")],1):_vm._e(),(_vm.ticketDetail.is_anonymous)?_c('b-button',{staticClass:"btn-save mr-2",attrs:{"variant":"custom-outline-primary"},on:{"click":_vm.onClickCreateUser}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"plus"}}),_vm._v(" Create Customer ")],1):_vm._e(),_c('button',{staticClass:"btn button mr-2",class:[
            { 'main-color btn-save': _vm.isShowActivity },
            { 'btn-mobile': !_vm.isShowActivity },
          ],attrs:{"type":"button"},on:{"click":function($event){_vm.isShowActivity = !_vm.isShowActivity}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"history"}}),_vm._v(" "+_vm._s(_vm.isShowActivity ? "Hide Activities" : "Show Activities")+" ")],1)]},proxy:true}])}),_c('b-tabs',{staticClass:"mt-3"},[_c('b-tab',{attrs:{"title":"Activity","active":""}},[_c('b-row',{staticClass:"no-gutters mt-3"},[_c('b-col',[_c('div',{staticClass:"left-panel"},[_c('TicketDetail',{attrs:{"ticketDetail":_vm.ticketDetail,"form":_vm.formDetail.form_field}}),_c('ActivityList',{attrs:{"ticketDetail":_vm.ticketDetail,"ticketId":_vm.ticketId,"isShowActivity":_vm.isShowActivity,"userProfile":_vm.userProfile,"pluginEmail":_vm.formDetail.pluginEmail},on:{"getDetail":_vm.getFormDetail}})],1)]),_c('b-col',{staticClass:"ml-2",attrs:{"sm":"4"}},[_c('div',{staticClass:"right-panel"},[_c('div',[(_vm.userProfile)?_c('TicketCustomerDetail',{attrs:{"profile":_vm.userProfile,"ticketDetail":_vm.ticketDetail}}):_vm._e()],1),(_vm.formDetail.form_field.length)?_c('div',{staticClass:"activity-form"},[_c('div',{staticClass:"form"},[_c('FormInputList',{attrs:{"items":_vm.formDetail.form_field || [],"status":_vm.formDetail.ticket.ticket_status_id || 0,"depth":0,"errors":_vm.$v.formDetail.form_field,"is-edit":true,"currentPage":_vm.currentPage,"rawAdmin":_vm.ticketDetail.ticket.assign_to,"isDeleted":_vm.ticketDetail.ticket.deleted,"permissionDisable":!_vm.$store.state.permission.Permission.response.includes(
                        72
                      )}})],1),(_vm.hasPage)?_c('div',{staticClass:"pagination-wrapper"},[_c('div',[_vm._v(_vm._s(_vm.currentPage)+" / "+_vm._s(_vm.totalPage))]),_c('div',{staticClass:"pagination-action"},[_c('b-button',{staticClass:"btn main-color btn-save w-100px",attrs:{"disabled":_vm.currentPage == 1},on:{"click":_vm.prevPage}},[_vm._v(" Previous")]),_c('b-button',{staticClass:"btn main-color btn-save w-100px",attrs:{"disabled":_vm.currentPage == _vm.totalPage},on:{"click":_vm.nextPage}},[_vm._v(" Next")])],1)]):_vm._e(),_c('div',{staticClass:"action py-2"},[_c('b-button',{staticClass:"main-color btn-save",attrs:{"disabled":!_vm.$store.state.permission.Permission.response.includes(
                        72
                      ) || _vm.ticketDetail.ticket.deleted == 1},on:{"click":_vm.sendForm}},[_vm._v(" Update ")])],1)]):_vm._e(),(_vm.ticketDetail.ticket.user_guid && _vm.ticketId)?_c('RecentActivity',{attrs:{"userId":_vm.ticketDetail.ticket.user_guid,"ticket-id":_vm.ticketId,"isDeleted":_vm.ticketDetail.ticket.deleted}}):_vm._e()],1)])],1)],1)],1)],1),_c('CreateNewUser',{ref:"newUser",attrs:{"notShow":"","ticket":""},on:{"onFinish":_vm.onFinish}}),_c('ModalCustomerList',{ref:"customerModal",attrs:{"selectedCustomer":_vm.selectedCustomer},on:{"select":_vm.handleSelectedCustomer}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }